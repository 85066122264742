div.dataTables_length label {
    float: left;
    text-align: left;
}

div.dataTables_length select {
    width: 75px;
}

div.dataTables_filter label {
    float: right;
}

div.dataTables_info {
    padding-top: 8px;
}

div.dataTables_paginate {
    float: right;
    margin: 0;
}

table.table {
    clear: both;
    margin-bottom: 6px !important;
    max-width: none !important;
}

table.table thead .sorting,
table.table thead .sorting_asc,
table.table thead .sorting_desc,
table.table thead .sorting_asc_disabled,
table.table thead .sorting_desc_disabled {
    /* cursor: pointer; */
    cursor: hand;
}

// table.table thead .sorting { background: url('../img/sort_both.png') no-repeat center right; }
// table.table thead .sorting_asc { background: url('../img/sort_asc.png') no-repeat center right; }
// table.table thead .sorting_desc { background: url('../img/sort_desc.png') no-repeat center right; }

// table.table thead .sorting_asc_disabled { background: url('../img/sort_asc_disabled.png') no-repeat center right; }
// table.table thead .sorting_desc_disabled { background: url('../img/sort_desc_disabled.png') no-repeat center right; }

table.dataTable th:active {
    outline: none;
}

/* Scrolling */
div.dataTables_scrollHead table {
    margin-bottom: 0 !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

div.dataTables_scrollHead table thead tr:last-child th:first-child,
div.dataTables_scrollHead table thead tr:last-child td:first-child {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

div.dataTables_scrollBody table {
    border-top: none;
    margin-bottom: 0 !important;
}

div.dataTables_scrollBody tbody tr:first-child th,
div.dataTables_scrollBody tbody tr:first-child td {
    border-top: none;
}

div.dataTables_scrollFoot table {
    border-top: none;
}

/*
 * TableTools styles
 */
.table tbody tr.active td,
.table tbody tr.active th {
    background-color: #08c;
    color: white;
}

.table tbody tr.active:hover td,
.table tbody tr.active:hover th {
    background-color: #0075b0 !important;
}

.table-striped tbody tr.active:nth-child(odd) td,
.table-striped tbody tr.active:nth-child(odd) th {
    background-color: #017ebc;
}

table.DTTT_selectable tbody tr {
    cursor: pointer;
    *cursor: hand;
}

div.DTTT .btn {
    color: #333 !important;
    font-size: 12px;
}

div.DTTT .btn:hover {
    text-decoration: none !important;
}

ul.DTTT_dropdown.dropdown-menu a {
    color: #333 !important; /* needed only when demo_page.css is included */
}

ul.DTTT_dropdown.dropdown-menu li:hover a {
    background-color: #0088cc;
    color: white !important;
}

/* TableTools information display */
div.DTTT_print_info.modal {
    height: 150px;
    margin-top: -75px;
    text-align: center;
}

div.DTTT_print_info h6 {
    font-weight: normal;
    font-size: 28px;
    line-height: 28px;
    margin: 1em;
}

div.DTTT_print_info p {
    font-size: 14px;
    line-height: 20px;
}

/*
 * FixedColumns styles
 */
div.DTFC_LeftHeadWrapper table,
div.DTFC_LeftFootWrapper table,
table.DTFC_Cloned tr.even {
    background-color: white;
}

div.DTFC_LeftHeadWrapper table {
    margin-bottom: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

div.DTFC_LeftHeadWrapper table thead tr:last-child th:first-child,
div.DTFC_LeftHeadWrapper table thead tr:last-child td:first-child {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

div.DTFC_LeftBodyWrapper table {
    border-top: none;
    margin-bottom: 0 !important;
}

div.DTFC_LeftBodyWrapper tbody tr:first-child th,
div.DTFC_LeftBodyWrapper tbody tr:first-child td {
    border-top: none;
}

div.DTFC_LeftFootWrapper table {
    border-top: none;
}

.dataTables_wrapper {
    position: relative;
    clear: both;
    zoom: 1; /* Feeling sorry for IE */
}

.dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 250px;
    margin-left: -125px;
    margin-top: -15px;
    padding: 10px 0;
    border: 1px solid #3b3b3b;
    border-radius: 3px;
    text-align: center;
    line-height: 14px;
    color: #3b3b3b;
    font-size: 14px;
    background-color: white;
}

.dataTables_length {
    width: 40%;
    float: left;
}

.dataTables_filter {
    width: 50%;
    float: right;
    text-align: right;
}

.dataTables_info {
    width: 60%;
    float: left;
}

.dataTables_paginate {
    float: right;
    text-align: right;
}

/* Pagination nested */
.paginate_disabled_previous,
.paginate_enabled_previous,
.paginate_disabled_next,
.paginate_enabled_next {
    height: 19px;
    float: left;
    cursor: pointer;
    *cursor: hand;
    color: #111 !important;
}
.paginate_disabled_previous:hover,
.paginate_enabled_previous:hover,
.paginate_disabled_next:hover,
.paginate_enabled_next:hover {
    text-decoration: none !important;
}
.paginate_disabled_previous:active,
.paginate_enabled_previous:active,
.paginate_disabled_next:active,
.paginate_enabled_next:active {
    outline: none;
}

.paginate_disabled_previous,
.paginate_disabled_next {
    color: #666 !important;
}
.paginate_disabled_previous,
.paginate_enabled_previous {
    padding-left: 23px;
}
.paginate_disabled_next,
.paginate_enabled_next {
    padding-right: 23px;
    margin-left: 10px;
}

// .paginate_disabled_previous {
//     background: url("../img/back_disabled.png") no-repeat top left;
// }

// .paginate_enabled_previous {
//     background: url("../img/back_enabled.png") no-repeat top left;
// }
// .paginate_enabled_previous:hover {
//     background: url("../img/back_enabled_hover.png") no-repeat top left;
// }

// .paginate_disabled_next {
//     background: url("../img/forward_disabled.png") no-repeat top right;
// }

// .paginate_enabled_next {
//     background: url("../img/forward_enabled.png") no-repeat top right;
// }
// .paginate_enabled_next:hover {
//     background: url("../img/forward_enabled_hover.png") no-repeat top right;
// }

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * DataTables display
 */
table.display {
    margin: 0 auto;
    clear: both;
    width: 100%;

    /* Note Firefox 3.5 and before have a bug with border-collapse
	 * ( https://bugzilla.mozilla.org/show%5Fbug.cgi?id=155955 )
	 * border-spacing: 0; is one possible option. Conditional-css.com is
	 * useful for this kind of thing
	 *
	 * Further note IE 6/7 has problems when calculating widths with border width.
	 * It subtracts one px relative to the other browsers from the first column, and
	 * adds one to the end...
	 *
	 * If you want that effect I'd suggest setting a border-top/left on th/td's and
	 * then filling in the gaps with other borders.
	 */
}

table.display thead th {
    padding: 3px 18px 3px 10px;
    border-bottom: 1px solid black;
    font-weight: bold;
    cursor: pointer;
}

table.display tfoot th {
    padding: 3px 18px 3px 10px;
    border-top: 1px solid black;
    font-weight: bold;
}

table.display tr.heading2 td {
    border-bottom: 1px solid #aaa;
}

table.display td {
    padding: 3px 10px;
}

table.display td.center {
    text-align: center;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * DataTables sorting
 */

// .sorting_asc {
//     background: url("../img/sort_asc.png") no-repeat center right;
// }

// .sorting_desc {
//     background: url("../img/sort_desc.png") no-repeat center right;
// }

// .sorting {
//     background: url("../img/sort_both.png") no-repeat center right;
// }

// .sorting_asc_disabled {
//     background: url("../img/sort_asc_disabled.png") no-repeat center right;
// }

// .sorting_desc_disabled {
//     background: url("../img/sort_desc_disabled.png") no-repeat center right;
// }

table.display thead th:active,
table.display thead td:active {
    outline: none;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * DataTables row classes
 */
table.display tr.odd.gradeA {
    background-color: #ddffdd;
}

table.display tr.even.gradeA {
    background-color: #eeffee;
}

table.display tr.odd.gradeC {
    background-color: #ddddff;
}

table.display tr.even.gradeC {
    background-color: #eeeeff;
}

table.display tr.odd.gradeX {
    background-color: #ffdddd;
}

table.display tr.even.gradeX {
    background-color: #ffeeee;
}

table.display tr.odd.gradeU {
    background-color: #ddd;
}

table.display tr.even.gradeU {
    background-color: #eee;
}

/* tr.odd {
	background-color: #E2E4FF;
}

tr.even {
	background-color: white;
} */

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Misc
 */
.dataTables_scroll {
    clear: both;
}

.dataTables_scrollBody {
    *margin-top: -1px;
    -webkit-overflow-scrolling: touch;
}

.top,
.bottom {
    padding: 15px;
    background-color: #f5f5f5;
    border: 1px solid #cccccc;
}

.top .dataTables_info {
    float: none;
}

.clear {
    clear: both;
}

.dataTables_empty {
    text-align: center;
}

tfoot input {
    margin: 0.5em 0;
    width: 100%;
    color: #444;
}

tfoot input.search_init {
    color: #999;
}

td.group {
    background-color: #d1cfd0;
    border-bottom: 2px solid #a19b9e;
    border-top: 2px solid #a19b9e;
}

td.details {
    background-color: #d1cfd0;
    /*border: 2px solid #A19B9E;*/
}

.example_alt_pagination div.dataTables_info {
    width: 40%;
}

.paging_full_numbers {
    width: 400px;
    height: 22px;
    line-height: 22px;
}

.paging_full_numbers a:active {
    outline: none;
}

.paging_full_numbers a:hover {
    text-decoration: none;
}

.paging_full_numbers a.paginate_button,
.paging_full_numbers a.paginate_active {
    border: 1px solid #aaa;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    padding: 2px 5px;
    margin: 0 3px;
    cursor: pointer;
    *cursor: hand;
    color: #333 !important;
}

.paging_full_numbers a.paginate_button {
    background-color: #ddd;
}

.paging_full_numbers a.paginate_button:hover {
    background-color: #ccc;
    text-decoration: none !important;
}

.paging_full_numbers a.paginate_active {
    background-color: #99b3ff;
}

table.display tr.even.row_selected td {
    background-color: #b0bed9;
}

table.display tr.odd.row_selected td {
    background-color: #9fafd1;
}

/*
 * Sorting classes for columns
 */
/* For the standard odd/even */
/* tr.odd td.sorting_1 {
	background-color: #D3D6FF;
}

tr.odd td.sorting_2 {
	background-color: #DADCFF;
}

tr.odd td.sorting_3 {
	background-color: #E0E2FF;
}

tr.even td.sorting_1 {
	background-color: #EAEBFF;
}

tr.even td.sorting_2 {
	background-color: #F2F3FF;
}

tr.even td.sorting_3 {
	background-color: #F9F9FF;
} */

/* For the Conditional-CSS grading rows */
/*
 	Colour calculations (based off the main row colours)
  Level 1:
		dd > c4
		ee > d5
	Level 2:
	  dd > d1
	  ee > e2
 */
tr.odd.gradeA td.sorting_1 {
    background-color: #c4ffc4;
}

tr.odd.gradeA td.sorting_2 {
    background-color: #d1ffd1;
}

tr.odd.gradeA td.sorting_3 {
    background-color: #d1ffd1;
}

tr.even.gradeA td.sorting_1 {
    background-color: #d5ffd5;
}

tr.even.gradeA td.sorting_2 {
    background-color: #e2ffe2;
}

tr.even.gradeA td.sorting_3 {
    background-color: #e2ffe2;
}

tr.odd.gradeC td.sorting_1 {
    background-color: #c4c4ff;
}

tr.odd.gradeC td.sorting_2 {
    background-color: #d1d1ff;
}

tr.odd.gradeC td.sorting_3 {
    background-color: #d1d1ff;
}

tr.even.gradeC td.sorting_1 {
    background-color: #d5d5ff;
}

tr.even.gradeC td.sorting_2 {
    background-color: #e2e2ff;
}

tr.even.gradeC td.sorting_3 {
    background-color: #e2e2ff;
}

tr.odd.gradeX td.sorting_1 {
    background-color: #ffc4c4;
}

tr.odd.gradeX td.sorting_2 {
    background-color: #ffd1d1;
}

tr.odd.gradeX td.sorting_3 {
    background-color: #ffd1d1;
}

tr.even.gradeX td.sorting_1 {
    background-color: #ffd5d5;
}

tr.even.gradeX td.sorting_2 {
    background-color: #ffe2e2;
}

tr.even.gradeX td.sorting_3 {
    background-color: #ffe2e2;
}

tr.odd.gradeU td.sorting_1 {
    background-color: #c4c4c4;
}

tr.odd.gradeU td.sorting_2 {
    background-color: #d1d1d1;
}

tr.odd.gradeU td.sorting_3 {
    background-color: #d1d1d1;
}

tr.even.gradeU td.sorting_1 {
    background-color: #d5d5d5;
}

tr.even.gradeU td.sorting_2 {
    background-color: #e2e2e2;
}

tr.even.gradeU td.sorting_3 {
    background-color: #e2e2e2;
}

/*
 * Row highlighting example
 */
.ex_highlight #example tbody tr.even:hover,
#example tbody tr.even td.highlighted {
    background-color: #ecffb3;
}

.ex_highlight #example tbody tr.odd:hover,
#example tbody tr.odd td.highlighted {
    background-color: #e6ff99;
}

.ex_highlight_row #example tr.even:hover {
    background-color: #ecffb3;
}

.ex_highlight_row #example tr.even:hover td.sorting_1 {
    background-color: #ddff75;
}

.ex_highlight_row #example tr.even:hover td.sorting_2 {
    background-color: #e7ff9e;
}

.ex_highlight_row #example tr.even:hover td.sorting_3 {
    background-color: #e2ff89;
}

.ex_highlight_row #example tr.odd:hover {
    background-color: #e6ff99;
}

.ex_highlight_row #example tr.odd:hover td.sorting_1 {
    background-color: #d6ff5c;
}

.ex_highlight_row #example tr.odd:hover td.sorting_2 {
    background-color: #e0ff84;
}

.ex_highlight_row #example tr.odd:hover td.sorting_3 {
    background-color: #dbff70;
}

/*
 * KeyTable
 */
table.KeyTable td {
    border: 3px solid transparent;
}

table.KeyTable td.focus {
    border: 3px solid #3366ff;
}

table.display tr.gradeA {
    background-color: #eeffee;
}

table.display tr.gradeC {
    background-color: #ddddff;
}

table.display tr.gradeX {
    background-color: #ffdddd;
}

table.display tr.gradeU {
    background-color: #ddd;
}

div.box {
    height: 100px;
    padding: 10px;
    overflow: auto;
    border: 1px solid #8080ff;
    background-color: #e5e5ff;
}

table.dt-rowReorder-float {
    position: absolute !important;
    opacity: 0.8;
    table-layout: fixed;
    outline: 2px solid #337ab7;
    outline-offset: -2px;
    z-index: 2001;
}

tr.dt-rowReorder-moving {
    outline: 2px solid #888;
    outline-offset: -2px;
}

body.dt-rowReorder-noOverflow {
    overflow-x: hidden;
}

table.dataTable td.reorder {
    text-align: center;
    cursor: move;
}

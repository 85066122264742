/* -------- MAIN ------- */
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
html {
    scroll-behavior: smooth;
}
html,
body,
#app {
    height: 100%;
}
body {
    background-color: #2b2b2b;
}
#app {
    display: flex;
    flex-direction: column;
}
#app #cuerpo {
    flex-grow: 1;
}

.close {
    float: right;
    font-size: 24px;
    font-weight: bold;
    line-height: 1;
    color: #000000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
    background-color: transparent;
    text-decoration: none !important;
}
.weight-600 {
    font-weight: 600;
}
.bold {
    font-weight: bold;
}
.border-2x {
    border-style: solid;
    border-width: 2px;
}
.hidden {
    overflow: hidden;
}
.pointer {
    cursor: pointer !important;
}
.rounded-cagetorias {
    border-radius: 2em !important;
}
.rounded-top-cagetorias {
    border-top-left-radius: 1.5em !important;
    border-top-right-radius: 1.5em !important;
}
.accordion-button:not(.collapsed)::after,
.accordion-button::after {
    background-image: unset !important;
}
.accordion-button {
    box-shadow: unset !important;
}
a {
    text-decoration: none;
}
button:focus {
    outline: 0 !important;
    box-shadow: unset !important;
}
.btn-group-xs > .btn,
.btn-xs {
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

.p-7 {
    padding: 7rem;
}
.absolute-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.subirarchivo {
    opacity: 0;
    width: 100%;
    cursor: pointer;
    z-index: 9999;
}
.check-position {
    position: absolute;
    top: 40%;
    left: 42%;
    cursor: pointer;
}
.form-control,
input,
.form-select {
    box-shadow: unset !important;
}
.form-select {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='rgb(41, 171, 226)' stroke='rgb(41, 171, 226)' stroke-width='3' d='M2 5l7 7 7-7'/%3e%3c/svg%3e");
}
.rounded-textarea {
    border-radius: 1em !important;
}
.border-filtro,
.border-perfil {
    border: solid 2px #29abe2;
}
.border-filtro-bottom,
.border-perfil-bottom {
    border-bottom: solid 2px #29abe2;
}
.border-filtro-top,
.border-perfil-top {
    border-top: solid 2px #29abe2;
}
#form-registro input::placeholder,
#form-ou input::placeholder,
#form-rc input::placeholder {
    color: #141414;
    font-weight: 600;
}
.border-rol-user {
    border-bottom: solid 2px #ffffff;
}
.btn-block {
    display: block;
}
ul li {
    list-style-type: none;
}
a:hover {
    text-decoration: underline !important;
    color: #29abe2 !important;
}
.a-face:hover,
.a-google:hover,
.a-cargar:hover,
.a-user:hover,
.a-premium:hover {
    text-decoration: none !important;
}
a.sociallink {
    color: #29abe2 !important;
    padding: 0 0 0 8px;
    &:hover {
        color: #808080 !important;
    }
}
.text-blue {
    color: #29abe2 !important;
}
.xl {
    font-size: 1.6rem;
}
.filtros div:hover {
    background-color: #29abe2 !important;
    color: #ffffff !important;
}
.filtros a:hover {
    text-decoration: none !important;
}
h1,
h2 {
    font-family: "signikabold";
}
h3,
h4,
h5,
h6 {
    font-family: "proxima_nova_condensedbold";
}
p,
span {
    font-family: "signikalight";
}
a.text-decoration-none:hover {
    text-decoration: none !important;
}
/* -------- FIN MAIN ------- */

/* -------- MAIN SWIPPER ------- */

.swiper-container {
    width: 100%;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.swiper-container .swiper-slide {
    background-size: cover;
    background-position: center;
}
.swiper-container .swiper-button-prev::after,
.swiper-container .swiper-button-next::after {
    font-size: 3em !important;
    font-weight: 900 !important;
}
.swiper-container2 {
    width: 100%;
    height: 100%;
    position: relative;
}
.swiper-container2 .swiper-button-prev::after,
.swiper-container2 .swiper-button-next::after {
    font-size: 2em !important;
    font-weight: 900 !important;
    color: #1c1b1a;
}
.swiper-container2 .swiper-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.swiper-container2 .swiper-slide img {
    display: block;
    width: 100%;
}
.swiper-container2 .swiper-button-prev,
.swiper-container2 .swiper-button-next {
    top: 40%;
}
/* -------- FIN MAIN SWIPPER------- */

/* -------- Colores ------- */

.bg-dark {
    background-color: #2b2b2b !important;
}
.bg-gray {
    background-color: #4d4d4d !important;
}
.bg-dark-footer {
    background-color: #141414 !important;
}
.bg-info {
    background-color: #29abe2 !important;
}
.bg-categorias {
    background-color: #999999;
}
.bg-acordeon,
.bg-perfil {
    background-color: #1c1c1c !important;
}
.bg-subir-video {
    background-color: #cccccc !important;
}
.bg-premium {
    background-color: #434142 !important;
}
.bg-success {
    background-color: #22b573 !important;
}
.bg-rol {
    background-color: #4caf50 !important;
}
.text-dark {
    color: #2b2b2b !important;
}
.text-grey {
    color: #808080 !important;
}

.text-white {
    color: #ffffff !important;
}
.text-danger {
    color: #f23838 !important;
}
.text-info {
    color: #29abe2 !important;
}
.text-acordeon {
    color: #434142 !important;
}
.border-info {
    border-color: #29abe2 !important;
}
.border-success {
    border-color: #22b573 !important;
}
.border-bottom-grey {
    border-bottom: solid 1px #808080 !important;
}
.border-gray {
    border: solid 1px #4d4d4d !important;
}
.border-bottom-gray {
    border-bottom: solid 1px #4d4d4d !important;
}
.border-acordeon {
    border-bottom: solid 2px #1c1c1c;
}
.border-bottom-categorias {
    border-bottom: solid 1px #b3b3b3;
}
.btn-white {
    background-color: #f2f2f2 !important;
    border: solid 1px #f2f2f2 !important;
}
.btn-dark {
    background-color: #2b2b2b !important;
    border: solid 1px #2b2b2b !important;
}
.btn-danger {
    background-color: #f23838 !important;
    border: solid 1px #f23838 !important;
}
.btn-info {
    background-color: #29abe2 !important;
    border: solid 1px #29abe2 !important;
}
.btn-success {
    background-color: #22b573 !important;
    border: solid 1px #22b573 !important;
}
/* ------- FIN COLORES-------- */

/* -------- HEADER ------- */

header,
ul li {
    font-family: "proxima_nova_condensedlight";
    font-weight: normal;
    font-style: normal;
}

.dropdown-item:hover {
    background-color: #363535 !important;
}
.navbar-brand img {
    width: 250px;
}
.navbar-collapse {
    transition: all 0.4s linear;
}

/* -------- FIN HEADER ------- */

@media (min-width: 576px) {
    /* -------- MAIN ------- */

    .check-position {
        top: 38%;
    }
    .border-filtro-bottom,
    .border-perfil-bottom {
        border-bottom: unset !important;
    }
    .border-filtro-top,
    .border-perfil-top {
        border-top: unset !important;
    }
    .border-filtro-start,
    .border-perfil-start {
        border-left: solid 2px #29abe2;
    }
    /* -------- FIN MAIN ------- */
}

/* -------- CELULARES ------- */
@media (max-width: 768px) {
    .dropdown-menu.bg-gray {
        background-color: #2b2b2b !important;
    }
    .dropdown-menu.border-gray {
        border-color: #2b2b2b !important;
    }
    .sponsors img {
        max-height: 20px;
    }
}
@media (min-width: 768px) {
    /* -------- HEADER ------- */

    .border-left-gray {
        border-left: solid 1px #4d4d4d !important;
    }
    .border-bottom-md-0 {
        border-bottom: unset !important;
    }
    .nav-item:hover {
        background-color: #4d4d4d !important;
    }
    .dropdown-menu {
        /*min-width: 20em !important;*/
        left: 0 !important;
        margin-top: 0.4rem !important;
    }

    .rounded {
        border-radius: 1rem !important;
        border-color: #4d4d4d !important;
    }
    .dropdown-menu::before {
        bottom: 100%;
        left: 15%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: hsla(0, 0%, 91.4%, 0) hsla(0, 0%, 91.4%, 0) #4d4d4d;
        border-width: 11px;
        margin-left: -11px;
    }

    /* -------- FIN HEADER ------- */

    /* -------- MAIN SWIPPER------- */

    .swiper-container2 .swiper-slide:first-child {
        transition: transform 100ms;
    }

    .swiper-container2 .swiper-slide:nth-child(2) {
        transition: transform 100ms;
    }

    .swiper-container2[dir="rtl"]
        .swiper-slide.swiper-slide-active:first-child {
        transform: translateX(-50%);
    }

    .swiper-container2[dir="rtl"] .swiper-slide.swiper-slide-next:nth-child(2) {
        transform: translateX(-55%);
    }
    .swiper-container2 .swiper-button-prev {
        left: -25px;
    }
    .swiper-container2 .swiper-button-next {
        right: -25px;
    }

    /* -------- FIN MAIN SWIPPER------- */
}

/*advanced table*/
tbody {
    border-color: rgb(33, 37, 41);
}
.adv-table table tr td {
    padding: 7px;
    vertical-align: middle;
}
.adv-table table tr td input {
    text-align: right;
}

.adv-table table.display thead th {
    border-bottom: 1px solid #dddddd;
    padding: 7px;
}

tr.odd.gradeA td.sorting_1,
tr.odd td.sorting_1,
tr.even.gradeA td.sorting_1 {
    background: none;
}

td.details {
    background-color: #eee;
}

td.details table tr td,
.dataTable tr:last-child {
    border: none;
}

.adv-table table.display tr.odd.gradeA {
    background-color: #f9f9f9;
}

.adv-table table.display tr.even.gradeA {
    background-color: #ffffff;
}

.adv-table .dataTables_filter label input {
    float: right;
    margin-left: 10px;
    width: 100%;
}

.adv-table .dataTables_filter label {
    line-height: 33px;
    width: 100%;
}

.adv-table .dataTables_wrapper .dataTables_length select {
    display: inline-block;
    margin: 0 10px 0 0;
    padding: 5px 8px;
    width: 65px;
}

.adv-table .dataTables_info,
.dataTables_paginate {
    padding: 15px 0;
}

.adv-table .dataTables_length,
.adv-table .dataTables_filter {
    padding: 15px 0;
}

/* video player */
.video-js.video-js-360 .vjs-big-play-button {
    width: 1.6em;
}

.video-js-32-9 {
    margin-bottom: 30px;
}

#publi {
    width: 150px;
    position: absolute;
    top: 10px;
    right: 22px;
    z-index: 99999;
}

canvas {
    height: 1920px;
    width: 1080px;
}

.fileinput-button {
    position: relative;
    overflow: hidden;
}
.fileinput-button input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}

@media (min-width: 992px) {
    /* -------- MAIN ------- */

    .check-position {
        top: 35%;
        left: 40%;
    }
    /* -------- FIN MAIN ------- */

    /* -------- MAIN SWIPPER ------- */

    .descripcion {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        background-color: #dee1e6;
        opacity: 0;
        transition: all 0.5s linear;
    }
    .sc .swiper-slide:hover img {
        opacity: 0.7;
    }
    .swiper-slide:hover .descripcion,
    .sc .swiper-slide:hover .descripcion {
        opacity: 0.6;
    }
    .sc .swiper-slide img,
    .sc .swiper-slide:first-child img {
        transition: all 0.2s linear;
    }
    .swiper-container2 .swiper-button-prev {
        left: -25px;
        top: 35%;
    }
    .swiper-container2 .swiper-button-next {
        right: -25px;
        top: 35%;
    }
    .fs-lg-08 {
        font-size: 0.8em;
    }

    /* -------- FIN MAIN SWIPPER ------- */
}
@media (min-width: 1200px) {
    /* -------- MAIN ------- */

    .check-position {
        left: 42%;
    }
    /* -------- FIN MAIN ------- */

    /* -------- MAIN SWIPPER ------- */

    .fs-xl-08 {
        font-size: 0.83em;
    }

    /* -------- FIN MAIN ------- */

    /* --------  MAIN SWIPPER ------- */

    .swiper-container2 .swiper-button-prev {
        left: -30px;
    }
    .swiper-container2 .swiper-button-next {
        right: -30px;
    }

    /* -------- FIN MAIN SWIPPER------- */
}
@media (min-width: 1300px) {
    /* -------- MAIN SWIPPER ------- */

    .fs-xl-08 {
        font-size: 0.9em;
    }

    /* -------- FIN MAIN ------- */
}
@media (min-width: 1400px) {
    /* --------  MAIN  ------- */

    .fs-xxl-1 {
        font-size: 1em;
    }

    /* -------- FIN MAIN------- */
}

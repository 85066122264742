@font-face {
    font-family: 'proxima_nova_condensedbold';
    src: url('../fonts/proxima_nova_cond_bold-webfont-webfont.woff2') format('woff2'),
         url('../fonts/proxima_nova_cond_bold-webfont-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'proxima_nova_condensedlight';
    src: url('../fonts/proximanovacond-light-webfont.woff2') format('woff2'),
         url('../fonts/proximanovacond-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'signikabold';
    src: url('../fonts/signika-bold-webfont.woff2') format('woff2'),
         url('../fonts/signika-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'signikalight';
    src: url('../fonts/signika-light-webfont.woff2') format('woff2'),
         url('../fonts/signika-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'signikasemibold';
    src: url('../fonts/signika-semibold-webfont.woff2') format('woff2'),
         url('../fonts/signika-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}